// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen-tabs[data-v-451cfba6] .or-tabs-v3 {
    margin-top: 16px;
    width: calc(100% - 32px);
    max-width: 1376px;
    align-self: center
}
@media (min-width: 900px) {
.full-screen-tabs[data-v-451cfba6] .or-tabs-v3 {
        margin-top: 32px;
        width: calc(100% - 64px)
}
}
[force-screen~="md"] .full-screen-tabs[data-v-451cfba6] .or-tabs-v3 {
    margin-top: 32px;
    width: calc(100% - 64px)
}
.full-screen-tabs[data-v-451cfba6] .or-tabs-v3[force-screen~="md"] {
    margin-top: 32px;
    width: calc(100% - 64px)
}`, "",{"version":3,"sources":["webpack://./src/components/RoutingTabsContainer.vue"],"names":[],"mappings":"AAGE;IAAA,gBAAA;IAAA,wBAAA;IAAA,iBAAA;IAAA;AAAA;AAAA;AAAA;QAAA,gBAAA;QAAA;AAAA;AAAA;AAAA;IAAA,gBAAA;IAAA;AAAA;AAAA;IAAA,gBAAA;IAAA;AAAA","sourcesContent":["$is-production: true;\n\n.full-screen-tabs:deep(.or-tabs-v3) {\n  @apply w-[calc(100%-32px)] md:w-[calc(100%-64px)] max-w-[1376px] self-center mt-md md:mt-xl;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
